import React, { Suspense, lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { useLocation } from 'react-router';
import { setConfiguration } from 'react-grid-system';

// xs, sm, md, lg, xl
setConfiguration({
  breakpoints: [480, 960, 1440],
  containerWidths: [480, 960, 1440],
  gutterWidth: 10,
  gridColumns: 16,
  defaultScreenClass: 'md',
});

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Services = lazy(() => import('./pages/Services'));
const Contact = lazy(() => import('./pages/Contact'));
const Cookies = lazy(() => import('./pages/Cookies'));
const CaseStudies = lazy(() => import('./pages/CaseStudies'));

// const Chama = lazy(() => import('./pages/CaseStudies/Chama'));
// const CryptoFOX = lazy(() => import('./pages/CaseStudies/CryptoFOX'));
// const DigitalInnovationCenter = lazy(() =>
//   import('./pages/CaseStudies/DigitalInnovationCenter')
// );
// const Indigitall = lazy(() => import('./pages/CaseStudies/Indigitall'));
// const Macros = lazy(() => import('./pages/CaseStudies/Macros'));

const App = () => (
  <Router>
    <ScrollToTop />
    <Suspense fallback={<div />}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/case-studies" exact component={CaseStudies} />
        {/*<Route path="/case-studies/macros" component={Macros} />*/}
        {/*<Route path="/case-studies/chama" component={Chama} />*/}
        {/*<Route path="/case-studies/cryptofox" component={CryptoFOX} />*/}
        {/*<Route*/}
        {/*  path="/case-studies/digital-innovation-center"*/}
        {/*  component={DigitalInnovationCenter}*/}
        {/*/>*/}
        {/*<Route path="/case-studies/indigitall" component={Indigitall} />*/}
        <Route path="/services" component={Services} />
        <Route path="/contact" component={Contact} />
        <Route path="/cookies-policy" component={Cookies} />

        {/* Redirect non existing case studies details to case studies page */}
        <Redirect from="/case-studies/*" to="/case-studies" />
        {/* Keep supporting old paths */}
        <Redirect from="/work" to="/case-studies" />
      </Switch>
    </Suspense>
  </Router>
);

export default App;
